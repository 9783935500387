<template>
  <div class="m-4">
    <b-row>
      <b-col>
        <h2 class="mb-4">Print Shop</h2>
      </b-col>
      <b-col class="text-right"> <b-button variant="primary" @click="showModal()">
          <BIconPlusLg class="mr-1" /> Create Print Set
        </b-button>
      </b-col>
    </b-row>
    <AddPrintSet ref="addPrintSetModal" />
    <b-alert variant="warning" :show="!this.prints.length" class="m-5">
      You don't have any print sets. Click <b-link @click="showModal()">Create Print Set</b-link> to add your first Print
      Set.
    </b-alert>
    <b-table :items="prints" :fields="fields" striped hover v-if="this.prints.length"
      @row-clicked="(item) => { $router.push({ name: 'printSet', params: { id: item._id } }) }">
      <template #cell(edit)="data">
        <b-button variant="primary" size="sm" @click="showModal(data.item._id)" v-b-tooltip.hover
          :title="`Edit ${data.item.title}`">
          <BIconPen />
        </b-button> &nbsp;
        <b-button variant="danger" size="sm" @click="onDeletePrintSet(data.item._id, data.item.title)" v-b-tooltip.hover
          :title="`Delete ${data.item.title}`">
          <BIconTrash />
        </b-button>

      </template>
      <template #cell(page)="data">
        {{
          getPageSize(data.item.page)
        }}
      </template>
      <template #cell(updated_on)="data">
        {{
          new Date(data.item.created_on).toLocaleDateString("en-us", {
            year: "numeric",
            month: "short",
            day: "numeric",
          })
        }}
      </template>
      <template #cell(created_on)="data">
        {{
          new Date(data.item.created_on).toLocaleDateString("en-us", {
            year: "numeric",
            month: "short",
            day: "numeric",
          })
        }}
      </template>
      <template #cell(status)="data">
        <b-badge variant="success" v-if="data.item.status === 'OPEN'">Open</b-badge>
        <b-badge variant="danger" v-else>Closed</b-badge>
      </template>
    </b-table>
  </div>
</template>

<script>
import { BIconPen, BIconTrash, BIconPlusLg } from "bootstrap-vue";
import { deletePrintSet } from "@/requests";
import { PAGE_SIZES } from "@/consts";
import AddPrintSet from "@/components/inputs/addPrintSet";
export default {
  name: "PrintGrid",
  data() {
    return {
      PAGE_SIZES,
      fields: [
        { key: "title", label: "Title", tdClass: "font-weight-bold" },
        { key: "status", label: "Status" },
        { key: "page", label: "Paper Size" },
        // { key: "printCount", label: "Print Count" },
        { key: "updated_on", label: "Last Updated" },
        { key: "created_on", label: "Created On" },
        {
          key: "edit", label: "",
          thStyle: { width: "175px" },
        },
      ],
    };
  },
  methods: {
    getPageSize(page) {
      const pageSize = PAGE_SIZES.find((size) => size.value === page);
      if (!pageSize) return "";
      return pageSize.shortName;
    },
    showModal(id) {
      this.$refs.addPrintSetModal.open(id);
    },
    onDeletePrintSet(id, title) {

      this.$bvModal.msgBoxConfirm(
        this.$createElement('div', { domProps: { innerHTML: `Are you sure you want to delete print set <strong>${title}<strong>?` } })
        , {
          cancelVariant: 'outline-primary',
          okVariant: 'danger',
          title: 'Delete Print Set',
        }).then((response) => {
          if (response) {
            deletePrintSet(id)
          }
        })
    },
  },
  computed: {
    prints() {
      return this.$store.getters.getPrints;
    },
  },
  mounted() {
    this.$store.dispatch("getPrints");
  },
  components: {
    BIconPen, AddPrintSet, BIconTrash, BIconPlusLg
  },
};
</script>
